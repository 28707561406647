<script>


  export default {
    name: "home",
    created() {
      this.$store.dispatch("cleanNavigationHistory")
      this.$store.dispatch("resetQuestionnaireState")

      this.$store.dispatch("setBackLogic", () => {
        this.$router.replace({name: "home"})
      })

    },
    methods: {
    }
  }
</script>

<template>
  <div class="my-is-under-navbar">
    <div class="section block">
      <div class="columns is-vcentered is-centered is-multiline is-mobile">
        <div class="column is-4-desktop">
          <div class="block">
            <div class="notification is-danger is-light has-text-centered section">
              <span class="icon has-text-danger is-large ">
                <i class="fas fa-3x fa-exclamation-triangle"></i>
              </span>
              <p class="is-size-4">{{ $t("pageNotFound") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<i18n>
{
  "it": {
    "pageNotFound": "Pagina non trovata"
  }
}
</i18n>

<style>



</style>
